import React, { ReactNode, createContext, useContext, useState } from "react";
import { BoardClient, SearchCollection, UserClient } from "~/types";

interface BoardConfigDetails {
  showAlerts: boolean;
  showAddColumn: boolean;
  name: string | undefined;
}

interface BoardConfig {
  [boardId: string]: BoardConfigDetails;
}

interface BoardConfigContext {
  config: BoardConfig;
  setAddColumn: (boardId: string) => (showAddColumn: boolean) => void;
  setShowAlerts: (boardId: string) => (showAlerts: boolean) => void;
  newTopCollections: SearchCollection[];
  user: UserClient;
  columnAlert:
    | {
        columnId: string;
        alertId: string;
      }
    | undefined;
  setColumnAlert: React.Dispatch<
    React.SetStateAction<
      | {
          columnId: string;
          alertId: string;
        }
      | undefined
    >
  >;
}

const BoardConfigContext = createContext<BoardConfigContext>({} as BoardConfigContext);

export const BoardConfitProvider: React.FC<{
  children: ReactNode;
  user: UserClient;
  newTopCollections: SearchCollection[];
}> = ({ children, user, newTopCollections }) => {
  const [config, setConfig] = useState<BoardConfig>({});
  const [columnAlert, setColumnAlert] = useState<{ columnId: string; alertId: string }>();

  const setAddColumn = (boardId: string) => (showAddColumn: boolean) => {
    setConfig((prev) => {
      const prevConfig = prev[boardId];
      if (showAddColumn) {
        setTimeout(() => {
          const container = document.getElementById("board-outlet");
          const target = document.getElementById("create-column");
          if (!target) return;
          container?.scroll({ left: target.offsetLeft, behavior: "smooth" });
        }, 100);
      }
      if (prevConfig) return { ...prev, [boardId]: { ...prevConfig, showAddColumn } };
      else
        return {
          ...prev,
          [boardId]: {
            showAlerts: false,
            showAddColumn,
            name: user.boards.find((v) => v._id === boardId)?.name,
          },
        };
    });
  };

  const setShowAlerts = (boardId: string) => (showAlerts: boolean) => {
    setConfig((prev) => {
      const prevConfig = prev[boardId];
      if (showAlerts) {
        setTimeout(() => {
          const container = document.getElementById("board-outlet");
          container?.scroll({ left: 0, behavior: "smooth" });
        }, 100);
      }
      if (prevConfig) return { ...prev, [boardId]: { ...prevConfig, showAlerts } };
      else
        return {
          ...prev,
          [boardId]: {
            showAddColumn: false,
            showAlerts,
            name: user.boards.find((v) => v._id === boardId)?.name,
          },
        };
    });
  };

  return (
    <BoardConfigContext.Provider
      value={{
        config,
        setAddColumn,
        setShowAlerts,
        newTopCollections,
        user,
        columnAlert,
        setColumnAlert,
      }}
    >
      {children}
    </BoardConfigContext.Provider>
  );
};

export const useBoardConfig = (boardId: string) => {
  const {
    config,
    setAddColumn,
    setShowAlerts,
    newTopCollections,
    user,
    setColumnAlert,
    columnAlert,
  } = useContext(BoardConfigContext);

  return {
    config: config[boardId] ?? {
      showAlerts: false,
      showAddColumn: false,
      name: "",
    },
    setAddColumn: setAddColumn(boardId),
    setShowAlerts: setShowAlerts(boardId),
    newTopCollections,
    wallets: user.wallets,
    board:
      user.boards.find((v) => v._id === boardId) ??
      ({
        _id: "",
        name: "",
        emojiId: "",
        order: 0,
        newAlertsCount: 0,
        columns: [],
      } as BoardClient),
    setColumnAlert,
    columnAlert,
  };
};
